import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import ReactModal from "react-modal"
import { Player, ControlBar, BigPlayButton } from "video-react"

function getParent() {
  return document.querySelector("#___gatsby")
}

export class HomePageTemplate extends Component {
  constructor() {
    super()
    this.state = {
      showModal: true,
    }
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

 componentDidMount = () => {
    setTimeout(() => {
      try {
        document.getElementsByClassName(
          "video-react-video"
        )[0].style.width ="inherit"

      } catch (err) {}
    }, 500)

    window.onresize = () => {
      console.log(
        Math.max(window.innerHeight, document.documentElement.clientHeight)
      )
      try {
        document.getElementsByClassName(
          "fullscreen-video-container"
        )[0].style.height =
          Math.max(window.innerHeight, document.documentElement.clientHeight) +
          "px"
        console.log("hello fixed")
      } catch (err) {}
    }
    
  }

  handleCloseModal() {
    window.location = "/home"
  }

  render() {
    const meta_title = this.props.meta_title
    const meta_description = this.props.meta_description
    const posts = this.props.posts

    return (
      <div>
        <Helmet>
          <title>{meta_title}</title>
          <meta name="description" content={meta_description} />
        </Helmet>
        <ReactModal
          isOpen={this.state.showModal}
          className="fullscreen-video-container"
          onRequestClose={this.handleCloseModal}
          parentSelector={getParent}
          style={{ position: "relative" }}
        >
          <a
            className="button is-light"
            id="home_page-video_button"
            href="/home"
            style={{ position: "relative" }}  
          >
            Skip Intro
          </a>
          <Player
            onEnded={this.handleCloseModal}
            fluid={false}
            height={"100%"}
            width={"100%"}
            playsInline
            src="/video/intro.mp4"
          >
            <ControlBar disableCompletely />
            <BigPlayButton position="center" />
          </Player>
        </ReactModal>
      </div>
    )
  }
}

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  vision: PropTypes.string,
  mission: PropTypes.string,
}

const HomePage = ({ data }) => {
  const { frontmatter } = data.index.edges[0].node
  const { edges: posts } = data.posts

  return (
    <HomePageTemplate
      title={frontmatter.title}
      meta_title={frontmatter.meta_title}
      meta_description={frontmatter.meta_description}
      heading={frontmatter.heading}
      description={frontmatter.description}
      vision={frontmatter.vision}
      mission={frontmatter.mission}
      posts={posts}
    />
  )
}

// HomePage.propTypes = {
//   data: PropTypes.shape({
//     markdownRemark: PropTypes.shape({
//       frontmatter: PropTypes.object,
//     }),
//     allMarkdownRemark: PropTypes.shape({
//       posts: PropTypes.object,
//     })
//   }),
// }

export default HomePage

export const pageQuery = graphql`
  query IndexPage {
    index: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            meta_title
            meta_description
            heading
            description
            vision
            mission
            offerings {
              blurbs {
                image
                text
              }
            }
            testimonials {
              author
              quote
            }
          }
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "media-page" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            cover
            templateKey
            date(formatString: "MMMM DD, YYYY")
            year
            meta_description
          }
        }
      }
    }
  }
`

